var _throttle = require('lodash/throttle');

function swiperBanner(rowBannerId) {
	const $rowBannerContainer = $(`#${rowBannerId}`);
	const $rowBannerSwiper = $rowBannerContainer.find('.columnswipeable .swiper-slide');
	// when Swiper is set to loop: true and autoplay, watchOverflow property fails to disable the slider when it only has one slide. So a workaround is to manually count the slides and disable.
	if ($rowBannerSwiper.length > 1) {
		new Swiper(`#${rowBannerId} .swiper-columnswipeable .swiper-container`, {
			observer: true,
			observeParents: true,
			spaceBetween: 5,
			loop: false,
			navigation: {
				nextEl: `#${rowBannerId} .swiper-columnswipeable .swiper-button-next`,
				prevEl: `#${rowBannerId} .swiper-columnswipeable .swiper-button-prev`
			},
			slidesPerView: 1,
			height: 200,
			autoHeight: true,
			autoplay: false,
			resistanceRatio: 0,
			simulateTouch: true
		});
		// disable arrows if only one slide.
	} else if ($rowBannerSwiper.length == 1) {
		$(`#${rowBannerId} .swiper-columnswipeable .swiper-button`).addClass("d-none");
	}

	// make full width if inner element is split block or text on image block and has full width set up
	var childSplitBlock = $rowBannerContainer.find('.pd-c-splitblock, .pd-c-textonimageblock');
	if (childSplitBlock.length > 0 && !childSplitBlock.hasClass('wrapper-1920')) {
		$rowBannerContainer.removeClass('wrapper-1920');
	}
}


function swiperSetupBanner() {
	Array.from(document.querySelectorAll(".pd-l-1columnswipeable"))
		.map((item) => item.id)
		.forEach((id) => {
			swiperBanner(id);
		});
}

// removing the throttle for mobile devices as the browser fires resize every time user scrolls (due to hiding and showing the nav bar)
if ($(window).width() > 768) {
	$(window).on('resize', _throttle(swiperSetupBanner, 250));
	swiperSetupBanner();
} else {
	swiperSetupBanner();
}
